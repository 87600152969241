import { GenericCardCarousel } from 'components/GenericCardCarousel';
import { parseRelatedProduct } from './helpers/parseRelatedProduct';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

const RelatedProducts = ({
  products,
  relatedProductsTitles,
}: {
  products: any[];
  relatedProductsTitles: {
    relatedProductsSubTitle: string;
    relatedProductsTitle: string;
    readMoreText: any;
  };
}) => {
  const { pathTranslations } = useGlobalContext();
  const carouselData = {
    subHeading: relatedProductsTitles?.relatedProductsSubTitle,
    heading: relatedProductsTitles?.relatedProductsTitle,
    readMoreText: relatedProductsTitles?.readMoreText,
  };
  const productList = parseRelatedProduct(products, pathTranslations, 'products');
  return (
    <div data-test="related-recipes" className="overflow-hidden" data-print="hide">
      <GenericCardCarousel {...carouselData} cardVariation={'product'} algoliaItems={productList} />
    </div>
  );
};

export default RelatedProducts;
