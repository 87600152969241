import type { PathTranslations } from 'service/localization/getPathTranslations';
import type { IProductData } from 'types/ICardData';

export const parseRelatedProduct = (
  data: IProductData[],
  pathTranslations: PathTranslations,
  linkText?: string,
): any[] =>
  data.map((product: IProductData) => ({
    id: product.id,
    title: product.productTitle,
    images: {
      desktop: product?.productImage?.desktop,
      mobile: product?.productImage?.mobile,
      mimeType: String(product?.productImage?.mimeType),
    },
    link: {
      url: `${pathTranslations.product}${product.slug}`,
      displayName: linkText || 'More',
    },
  }));
